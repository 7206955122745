import useSWR from 'swr';

import axios from './axios';

const fetcher = (method) => (url) =>
  axios[method](url).then((response) => response.data);

export const useGet = (url, options) => {
  const { data, error, mutate } = useSWR(url, fetcher('get'), options);

  return {
    data,
    error,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
