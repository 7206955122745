import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { filter, propEq } from 'ramda';

import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import axios from '../../../utils/axios';
import { useGet } from '../../../utils/fetch';

import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  const { data: notifications, mutate } = useGet('/notifications', {
    refreshInterval: 120000,
  });

  // const [notifications, setNotifications] = useState([]);
  const unreadNotifications = useMemo(
    () => filter(propEq('read', false), notifications || []),
    [notifications],
  );
  const readNotifications = useMemo(
    () => filter(propEq('read', true), notifications || []),
    [notifications],
  );

  const handleMarkAllAsRead = async () => {
    const payload =
      unreadNotifications.map((notification) => notification.id) || [];

    await axios.put('/notifications/read', payload);

    setTimeout(() => {
      mutate('/notifications');
    }, 500);
  };

  useEffect(() => {
    if (open && !hasBeenOpened) {
      setHasBeenOpened(true);
    }

    if (!open && hasBeenOpened) {
      handleMarkAllAsRead();
    }
  }, [open, hasBeenOpened]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={unreadNotifications.length} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {unreadNotifications.length} unread messages
            </Typography>
          </Box>

          {unreadNotifications.length > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {unreadNotifications && unreadNotifications.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{ py: 1, px: 2.5, typography: 'overline' }}
                >
                  New
                </ListSubheader>
              }
            >
              {unreadNotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </List>
          )}

          {readNotifications && readNotifications.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{ py: 1, px: 2.5, typography: 'overline' }}
                >
                  Before that
                </ListSubheader>
              }
            >
              {readNotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </List>
          )}
        </Scrollbar>

        {/* <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    read: PropTypes.bool,
    message: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
  }),
};

function NotificationItem({ notification, onIsVisible }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      to={notification.link || '#'}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.read && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {moment(notification.createdAt).fromNow()}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.message}
      {/* <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        &nbsp; {noCase(notification.description)}
      </Typography> */}
    </Typography>
  );

  if (notification.icon.includes('http')) {
    return {
      avatar: <img alt={notification.message} src={notification.icon} />,
      title,
    };
  }
  return {
    avatar: notification.icon ? (
      <Iconify icon={notification.icon} width={24} height={24} />
    ) : null,
    title,
  };
}
