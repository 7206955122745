import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
// @mui
import { Typography, Link } from '@mui/material';
// utils
import GetFontValue from '../utils/getFontValue';

// ----------------------------------------------------------------------

const TextMaxLine = forwardRef(
  (
    {
      asLink,
      variant = 'body1',
      line = 2,
      persistent = false,
      children,
      sx,
      includeExpandLink = false,
      ...other
    },
    ref,
  ) => {
    const [showLink, setShowLink] = useState(false);
    const [expanded, setExpanded] = useState(
      ref?.current?.clientHeight <= ref?.current?.scrollHeight,
    );
    const { lineHeight } = GetFontValue(variant);

    const style = {
      ...(!expanded
        ? {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: line,
          }
        : {}),
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      ...(persistent && {
        height: lineHeight * line,
      }),
      ...sx,
    };

    useEffect(() => {
      if (includeExpandLink) {
        setTimeout(() => {
          setShowLink(ref?.current?.clientHeight < ref?.current?.scrollHeight);
        }, 1000);
      }
    }, [includeExpandLink]);

    if (asLink) {
      return (
        <Link
          color="inherit"
          ref={ref}
          variant={variant}
          sx={{ ...style }}
          {...other}
        >
          {children}
        </Link>
      );
    }

    return (
      <>
        <Typography ref={ref} variant={variant} sx={{ ...style }} {...other}>
          {children}
        </Typography>
        {includeExpandLink && showLink && !expanded && (
          <Link
            color="primary"
            variant="subtitle2"
            onClick={() => setExpanded(true)}
            sx={{ cursor: 'pointer', mt: 0.5 }}
          >
            See more
          </Link>
        )}
      </>
    );
  },
);

TextMaxLine.propTypes = {
  asLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  line: PropTypes.number,
  persistent: PropTypes.bool,
  sx: PropTypes.object,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2',
  ]),
};

export default TextMaxLine;
