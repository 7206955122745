import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';

import Iconify from '../../../components/Iconify';

const navConfig = [
  {
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.root,
        icon: <Iconify icon="ic:round-dashboard" />,
      },
      {
        title: 'Browse',
        path: PATH_PAGE.browse,
        icon: <Iconify icon="eva:film-outline" />,
      },
      {
        title: 'Award Shows',
        path: PATH_PAGE.events,
        icon: <Iconify icon="ic:round-emoji-events" />,
      },
      {
        title: 'Leagues',
        path: PATH_PAGE.league,
        icon: <Iconify icon="eva:calendar-fill" />,
      },
      {
        title: 'Watchlist',
        path: PATH_PAGE.watchlist,
        icon: <Iconify icon="eva:eye-outline" />,
      },
      {
        title: 'Draft list',
        path: PATH_PAGE.list,
        icon: <Iconify icon="eva:list-fill" />,
      },
      {
        title: 'Rules & Scoring',
        path: PATH_PAGE.rulesScoring,
        icon: <Iconify icon="eva:book-fill" />,
      },
    ],
  },
];

export default navConfig;
