import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { useNavigate } from 'react-router-dom';
//
import { AUTH0_API } from '../config';
import axios, { setBearerToken } from '../utils/axios';
import { PATH_AUTH } from '../routes/paths';
import useLeagues from '../hooks/useLeagues';

// ----------------------------------------------------------------------

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  provider: null,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, provider, user } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, provider, user };
  },
  LOGIN: (state, action) => {
    const { provider, user } = action.payload;
    return { ...state, isAuthenticated: true, provider, user };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    provider: null,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'auth0',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { reset: resetLeagues } = useLeagues();

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          audience: AUTH0_API.audience,
          client_id: AUTH0_API.clientId,
          domain: AUTH0_API.domain,
          redirect_uri: window.location.origin,
          scope: 'read:current_user update:current_user_metadata',
          cacheLocation: 'localstorage',
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const accessToken = await auth0Client.getTokenSilently();
          await setBearerToken(accessToken);

          const { data } = await axios.get(`/auth/user/${user.sub}`);

          if (isEmpty(data)) {
            dispatch({
              type: 'INITIALIZE',
              payload: { isAuthenticated, provider: user, user: null },
            });
            navigate(PATH_AUTH.register, { replace: true });
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated,
                provider: user,
                user: data,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated, provider: null, user: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: { isAuthenticated: false, provider: null, user: null },
        });
      }
    };

    initialize();
  }, []);

  const login = async () => {
    await auth0Client.loginWithPopup();
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const providerUser = await auth0Client.getUser();
      const accessToken = await auth0Client.getTokenSilently();
      await setBearerToken(accessToken);

      const { data: user } = await axios.get(`/auth/user/${providerUser.sub}`);

      if (typeof user !== 'string') {
        dispatch({
          type: 'LOGIN',
          payload: { provider: providerUser, user },
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            provider: providerUser,
            user: null,
          },
        });
        navigate(PATH_AUTH.register, { replace: true });
      }
    }
  };

  const register = async (values, hasAuthAccount) => {
    if (!hasAuthAccount) {
      await auth0Client.loginWithPopup({
        login_hint: values.email,
        screen_hint: 'signup',
      });
    }
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const providerUser = await auth0Client.getUser();
      const accessToken = await auth0Client.getTokenSilently();
      await setBearerToken(accessToken);

      const { data: user } = await axios.post('/auth/user', {
        values,
        auth0: providerUser,
      });

      dispatch({
        type: 'LOGIN',
        payload: { provider: providerUser, user },
      });
    }
  };

  const logout = (redirect) => {
    auth0Client.logout({
      returnTo: `${window.location.origin}/logout?redirect=${encodeURIComponent(
        redirect,
      )}`,
    });
    dispatch({ type: 'LOGOUT' });
    resetLeagues();
  };

  const resetPassword = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        login,
        logout,
        register,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
