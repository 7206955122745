import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';

import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';

import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

import Avatar from '../../../components/Avatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

// ---------------------------------------------------------------------- isAuthenticated,

export default function AccountPopover() {
  const location = useLocation();
  const anchorRef = useRef(null);
  const { user, logout, login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const menuOptions = useMemo(
    () => [
      {
        label: 'Home',
        linkTo: '/',
      },
      ...(user
        ? [
            {
              label: 'Profile',
              linkTo: `${PATH_DASHBOARD.user.profile}/${user.uuid}`,
            },
          ]
        : []),
      // {
      //   label: 'Settings',
      //   linkTo: PATH_DASHBOARD.user.account,
      // },
    ],
    [user],
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout?.(location.pathname);

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  if (user) {
    return (
      <>
        <IconButtonAnimate
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Avatar />
        </IconButtonAnimate>

        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 220 }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {user?.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user?.email}
            </Typography>
          </Box>

          <Divider />
          <Stack spacing={0.5} sx={{ p: 1 }}>
            {menuOptions.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
                sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Stack>
          <Divider />

          <MenuItem
            onClick={handleLogout}
            sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1, m: 1 }}
          >
            Logout
          </MenuItem>
        </MenuPopover>
      </>
    );
  }

  return (
    <>
      <Button variant="contained" onClick={() => login()}>
        Login
      </Button>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        or
      </Typography>
      <Button component={RouterLink} to={PATH_AUTH.register}>
        Register
      </Button>
    </>
  );
}
