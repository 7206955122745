import useAuth from '../../hooks/useAuth';

import createAvatar from '../../utils/createAvatar';

import Avatar from './base';

export default function MyAvatar({ editable, sx, user: passedUser, ...other }) {
  const { user: currentUser } = useAuth();

  const user = passedUser || currentUser;

  return (
    <Avatar
      src={user?.image}
      alt={user?.displayName}
      color="primary"
      sx={sx}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
