import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClick = async () => {
    await login();
    setIsSubmitting(true);
  };

  return (
    <LoadingButton
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      loading={isSubmitting}
      onClick={onClick}
    >
      Login
    </LoadingButton>
  );
}
