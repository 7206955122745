import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { is, map, pluck } from 'ramda';

import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  ClickAwayListener,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import useAuth from '../../../hooks/useAuth';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import axios from '../../../utils/axios';
import Iconify from '../../../components/Iconify';
import WatchButton from '../../../components/WatchButton';
import { IconButtonAnimate } from '../../../components/animate';
import TextMaxLine from '../../../components/TextMaxLine';
import { PATH_PAGE } from '../../../routes/paths';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
let savedQuery = null;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ResultsStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ opacity: 0.95 }),
  top: APPBAR_MOBILE,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  justifyContent: 'flex-start',
  maxHeight: `calc(100vh - ${APPBAR_MOBILE}px)`,
  height: '100vh',
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  overflowY: 'auto',
  [theme.breakpoints.up('md')]: {
    top: APPBAR_DESKTOP,
    maxHeight: '40vh',
    height: '100vh',
    padding: theme.spacing(2, 3),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isWatched, setIsWatched] = useState({});

  useDebouncedEffect(
    () => {
      let active = true;

      if (!isOpen || savedQuery === inputValue || inputValue === '') {
        setIsLoading(false);
        return undefined;
      }

      setIsLoading(true);
      setHasSearched(true);

      savedQuery = inputValue;

      (async () => {
        setIsWatched({});
        const { data } = await axios.get(`/search?q=${inputValue}`);

        if (active) {
          setOptions(data.results);
        }

        if (isAuthenticated) {
          const { data: watchedData } = await axios.post(
            `/watchlist/discovery`,
            map(
              (tmdbId) => (is(Number) ? tmdbId.toString() : tmdbId),
              pluck(['id'], data.results),
            ),
          );

          setIsWatched((prev) => ({ ...prev, ...watchedData }));
        }
        setIsLoading(false);
      })();

      return () => {
        active = false;
      };
    },
    [inputValue],
    500,
  );

  useEffect(() => {
    if (inputValue) setIsLoading(true);
    if (inputValue === '') setOptions([]);
  }, [inputValue]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFilmSelect = (option) => () => {
    setOptions([]);
    handleClose();
    setHasSearched(false);
    navigate(`${PATH_PAGE.movie}/${option.id}`);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              value={inputValue}
              startAdornment={
                <InputAdornment position="start" sx={{ mr: 4 }}>
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              endAdornment={
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ mr: 2 }}
                >
                  {hasSearched && (
                    <Typography
                      variant="subtitle2"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {options.length} results
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </Stack>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              onChange={(event) => setInputValue(event.target.value)}
            />
            <Button variant="contained" onClick={handleClose}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>

        <Slide
          direction="down"
          in={isOpen && options.length > 0}
          mountOnEnter
          unmountOnExit
        >
          <ResultsStyle>
            <Grid container spacing={2} direction="row" sx={{ mb: 2 }}>
              {options.map((option) => (
                <Grid key={option.id} item xs={12} md={4}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={onFilmSelect(option)}
                    sx={{
                      cursor: 'pointer',
                      height: 116,
                      borderRadius: 2,
                      p: 2,
                      color: 'text.primary',
                      transition: 'background-color 100ms',
                      ':hover': {
                        backgroundColor: 'grey.50024',
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {option.poster_path && (
                        <img
                          src={option.poster_path}
                          style={{ height: 100 }}
                          alt={option.title}
                        />
                      )}
                      <Typography variant="subtitle1">
                        <TextMaxLine line={3} sx={{ pl: 2 }}>
                          {`${option.title}${
                            option?.release_date
                              ? ` (${option?.release_date?.split('-')[0]})`
                              : ''
                          }`}
                        </TextMaxLine>
                      </Typography>
                    </Stack>
                    <Box sx={{ flexShrink: 0 }}>
                      <WatchButton
                        movie={{ ...option, watchlistId: isWatched[option.id] }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </ResultsStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
