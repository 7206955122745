import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';

import { Button, IconButton, Tooltip } from '@mui/material';

import { useSnackbar } from 'notistack';

import Iconify from './Iconify';

import useEvents from '../hooks/useEvents';
import useAuth from '../hooks/useAuth';
import axios from '../utils/axios';

import { PATH_PAGE } from '../routes/paths';

export default function WatchButton({ movie, sx }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useAuth();
  const { setUpdate } = useEvents();
  const [watchlistId, setWatchlistId] = useState(movie.watchlistId || null);

  useEffect(() => {
    setWatchlistId(movie?.watchlistId);
  }, [movie?.watchlistId]);

  const handleReviewClick = () => {
    navigate(`${PATH_PAGE.reviews}/${movie.id}`);
  };

  const postItem = (tmdbId) => async (event) => {
    event.stopPropagation();

    const { data } = await axios.post(`/watchlist/item`, { tmdbId });

    setWatchlistId(data.id);

    setUpdate(moment().valueOf());

    enqueueSnackbar('Marked as watched', {
      variant: 'success',
      action: <Button onClick={handleReviewClick}>Write a review</Button>,
    });
  };

  const deleteItem = () => async (event) => {
    event.stopPropagation();

    await axios.delete(`/watchlist/item/${movie.watchlistId || watchlistId}`);

    setUpdate(moment().valueOf());

    setWatchlistId(null);
  };

  if (isAuthenticated) {
    return (
      <>
        <Tooltip title={!watchlistId ? 'Mark as watched' : 'Watched!'}>
          <IconButton
            size="medium"
            variant="contained"
            onClick={!watchlistId ? postItem(movie.id) : deleteItem()}
            sx={sx}
          >
            <Iconify
              icon={
                !watchlistId
                  ? 'eva:plus-circle-fill'
                  : 'eva:checkmark-circle-2-fill'
              }
            />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return null;
}
