import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Tooltip } from '@mui/material';

//
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../../components/animate';

ToggleButton.propTypes = {
  notDefault: PropTypes.bool,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
};

export default function ToggleButton({ onToggle }) {
  return (
    <Tooltip title="Settings">
      <IconButtonAnimate
        onClick={onToggle}
        sx={{
          transition: (theme) => theme.transitions.create('all'),
          '&:hover': {
            color: 'primary.main',
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.hoverOpacity,
              ),
          },
        }}
      >
        <Iconify icon="eva:options-2-fill" width={20} height={20} />
      </IconButtonAnimate>
    </Tooltip>
  );
}
