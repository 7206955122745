import useSettings from '../../hooks/useSettings';

import { defaultSettings } from '../../config';

import ToggleButton from './ToggleButton';

export default function Settings() {
  const {
    themeMode,
    themeDirection,
    themeColorPresets,
    themeStretch,
    open,
    setOpen,
  } = useSettings();

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets ||
    themeStretch !== defaultSettings.themeStretch;

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  if (!open) {
    return (
      <ToggleButton
        open={open}
        notDefault={notDefault}
        onToggle={handleToggle}
      />
    );
  }

  return null;
}
