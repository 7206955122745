import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import { find, isEmpty, keys, propEq } from 'ramda';

import axios from '../utils/axios';

// import { PATH_PAGE } from '../routes/paths';
import { ACTIVE_YEAR } from '../config';

const initialState = {
  activeDraftId: null,
  activeGroup: null,
  activeLeague: null,
  draftYear: ACTIVE_YEAR,
  groups: null,
  league: null,
  leagues: [],
  view: null,
  setLeagues: () => {},
  setActiveDraftId: () => {},
  setActiveGroup: () => {},
  setActiveLeague: () => {},
  setDraftYear: () => {},
  setGroups: () => {},
  setView: () => {},
  fetchDrafts: () => {},
  fetchLeagues: () => {},
  reset: () => {},
};

const LeaguesContext = createContext(initialState);

LeaguesProvider.propTypes = {
  children: PropTypes.node,
};

function LeaguesProvider({ children }) {
  // const navigate = useNavigate();
  const [leagues, setLeagues] = useState(initialState.leagues);
  const [groups, setGroups] = useState(initialState.groups);
  const [activeLeague, setActiveLeague] = useState(initialState.activeLeague);
  const [draftYear, setDraftYear] = useState(initialState.draftYear);
  const [view, setView] = useState(initialState.view);
  const [activeDraftId, setActiveDraftId] = useState(
    initialState.activeDraftId,
  );
  const [activeGroup, setActiveGroup] = useState(initialState.activeGroups);

  const league = useMemo(() => {
    return activeLeague && !isEmpty(leagues)
      ? find(propEq('id', parseInt(activeLeague, 10)), leagues || [])
      : null;
  }, [activeLeague, leagues]);

  const draft = useMemo(() => {
    return activeDraftId &&
      activeGroup &&
      !isEmpty(groups) &&
      !isEmpty(groups[activeGroup])
      ? find(
          propEq('id', parseInt(activeDraftId, 10)),
          groups[activeGroup] || [],
        )
      : null;
  }, [groups, activeGroup, activeDraftId]);

  const fetchDrafts = async (year = draftYear) => {
    const { data } = await axios.get(
      `/league/${activeLeague}/draft/${year}`,
    );

    if (isEmpty(data)) return fetchDrafts(year - 1);

    if (draftYear !== year) setDraftYear(year);

    setGroups(data);

    if (!activeGroup && keys(data)[0] && keys(data)[0] !== 'null') {
      setActiveGroup(parseInt(keys(data)[0], 10));
    }
  };

  const fetchLeagues = async (id) => {
    let data;
    const { data: userLeagues } = await axios.get(`/league/${id || 'user'}`);

    if (!isEmpty(userLeagues)) {
      setLeagues(userLeagues);
      data = userLeagues;
    } else if (id) {
      const { data: leagueData } = await axios.get(`/league/${id}`);
      setLeagues(leagueData);
      data = leagueData;
    }

    return data;
  };

  const reset = async () => {
    setLeagues(initialState.leagues);
    setGroups(initialState.groups);
    setActiveLeague(initialState.activeLeague);
    setDraftYear(initialState.draftYear);
    setView(initialState.view);
    setActiveDraftId(initialState.activeDraftId);
  };

  return (
    <LeaguesContext.Provider
      value={{
        activeLeague,
        setActiveLeague,
        leagues,
        fetchLeagues,
        setLeagues,
        draftYear,
        setDraftYear,
        view,
        setView,
        groups,
        setGroups,
        league,
        activeGroup,
        setActiveGroup,
        activeDraftId,
        setActiveDraftId,
        draft,
        fetchDrafts,
        reset,
      }}
    >
      {children}
    </LeaguesContext.Provider>
  );
}

export { LeaguesProvider, LeaguesContext };
