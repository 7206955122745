import PropTypes from 'prop-types';
import moment from 'moment';
import { createContext, useState } from 'react';

import { ACTIVE_YEAR } from '../config';

const initialState = {
  activeYear: ACTIVE_YEAR,
  nomineeId: null,
  selectedAward: null,
  selectedFilm: null,
  update: moment().valueOf(),
  onAwardSelected: () => {},
  onFilmSelected: () => {},
  setActiveYear: () => {},
  setNomineeId: () => {},
  setUpdate: () => {},
};

const EventsContext = createContext(initialState);

EventsProvider.propTypes = {
  children: PropTypes.node,
};

function EventsProvider({ children }) {
  const [selectedAward, setSelectedAward] = useState(null);
  const [selectedFilm, setSelectedFilm] = useState(null);
  const [nomineeId, setNomineeId] = useState(null);
  const [activeYear, setActiveYear] = useState(ACTIVE_YEAR);
  const [update, setUpdate] = useState(moment().valueOf());

  return (
    <EventsContext.Provider
      value={{
        activeYear,
        selectedAward,
        selectedFilm,
        nomineeId,
        onAwardSelected: setSelectedAward,
        onFilmSelected: setSelectedFilm,
        setActiveYear,
        setNomineeId,
        update,
        setUpdate,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
}

export { EventsProvider, EventsContext };
