// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  browse: '/browse',
  events: '/award-shows',
  list: '/list',
  live: '/live',
  join: '/join',
  league: '/leagues',
  movie: '/movie',
  page404: '/404',
  page500: '/500',
  reviews: '/reviews',
  rulesScoring: '/rules-and-scoring',
  watchlist: '/watchlist',
  logoutRedirect: '/logout',
};

export const PATH_LEAGUES = {
  root: PATH_PAGE.league,
  create: path(PATH_PAGE.league, '/create'),
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
};
