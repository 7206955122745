import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    ),
);

export const setBearerToken = (accessToken) =>
  new Promise((resolve) => {
    if (accessToken) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      localStorage.setItem('cd_bear', accessToken);
    } else {
      axiosInstance.defaults.headers.common.Authorization = null;
    }

    return resolve();
  });

export default axiosInstance;
