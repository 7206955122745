import { useState, useEffect } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';

import { fill } from '@cloudinary/url-gen/actions/resize';

import { CLOUDINARY_NAME } from '../config';

const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_NAME,
  },
});

export default function useUserImage(value) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (value) {
      if (value.startsWith('http')) {
        setSrc(value);
      } else {
        const image = cld.image(value);
        image.resize(fill().width(128).height(128));
        setSrc(image.toURL());
      }
    }
  }, [value]);

  return [src];
}
