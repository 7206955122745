import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, useEffect, useCallback } from 'react';
// @mui
import { Box } from '@mui/material';
// utils
import track from '../utils/analytics';

import { APP_NAME } from '../config';

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title: passedTitle = '', ...other }, ref) => {
    const { pathname } = useLocation();
    const title =
      passedTitle && passedTitle !== ''
        ? `${passedTitle} | ${APP_NAME}`
        : `${APP_NAME} | Draft your movies - dominate your friends`;

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: pathname,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta
            property="og:description"
            content="Get your cinema draft on! Collect your friends, pick you movie roster, and wait for Award Season to unfold so you can flaunt your cinematic knowledge."
          />
          <meta
            name="description"
            content="Get your cinema draft on! Collect your friends, pick you movie roster, and wait for Award Season to unfold so you can flaunt your cinematic knowledge."
          />
          <meta
            name="keywords"
            content="movie,movies,film,films,draft,cinema,fantasy,league"
          />
          <meta name="author" content="Cinamadraft" />
        </Helmet>
        {children}
      </Box>
    );
  },
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
