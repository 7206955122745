import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_LEAGUES, PATH_PAGE } from './paths';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const Browse = Loadable(lazy(() => import('../pages/browse')));
const List = Loadable(lazy(() => import('../pages/list')));
const Movie = Loadable(lazy(() => import('../pages/movie')));
const Watchlist = Loadable(lazy(() => import('../pages/watchlist')));
const Reviews = Loadable(lazy(() => import('../pages/reviews')));
const RulesScoring = Loadable(lazy(() => import('../pages/rulesScoring')));
const Events = Loadable(lazy(() => import('../pages/events')));
const EventsSingle = Loadable(lazy(() => import('../pages/events/single')));
const LiveEvent = Loadable(lazy(() => import('../pages/live')));
const EventsViewPanel = Loadable(
  lazy(() => import('../pages/events/viewPanel')),
);
const Join = Loadable(lazy(() => import('../pages/join')));
const League = Loadable(lazy(() => import('../pages/league')));
const LeagueCreate = Loadable(lazy(() => import('../pages/league/create')));
const LeagueRedirect = Loadable(lazy(() => import('../pages/league/redirect')));
const LogoutRedirect = Loadable(lazy(() => import('../pages/Logout')));

const UserProfile = Loadable(lazy(() => import('../pages/users/UserProfile')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'register',
          element: <Register />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Dashboard /> },
        { path: '/browse', element: <Browse /> },
        { path: '/list', element: (
          <AuthGuard>
            <List />
          </AuthGuard>
        )},
        {
          path: PATH_PAGE.events,
          children: [
            { element: <Events />, index: true },
            {
              path: ':abbreviation',
              element: <EventsSingle />,
              children: [{ path: ':view', element: <EventsViewPanel /> }],
            },
          ],
        },
        {
          path: `${PATH_PAGE.live}/:abbreviation`,
          element: <LiveEvent />,
        },
        {
          path: PATH_LEAGUES.root,
          element: (
            <AuthGuard>
              <LeagueRedirect />
            </AuthGuard>
          ),
        },
        {
          path: PATH_LEAGUES.create,
          element: (
            <AuthGuard>
              <LeagueCreate />
            </AuthGuard>
          ),
        },
        {
          path: `${PATH_PAGE.league}/:leagueId/:view/:activeGroup`,
          element: <League />,
        },
        {
          path: `${PATH_PAGE.movie}/:id`,
          element: <Movie />,
        },
        {
          path: PATH_PAGE.watchlist,
          element: (
            <AuthGuard>
              <Watchlist />
            </AuthGuard>
          ),
        },
        {
          path: `${PATH_PAGE.reviews}/:tmdbId`,
          element: (
            <AuthGuard>
              <Reviews />
            </AuthGuard>
          ),
        },
        {
          path: PATH_PAGE.rulesScoring,
          element: <RulesScoring />,
        },
        {
          path: `${PATH_PAGE.join}/:uuid`,
          element: <Join />,
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            {
              path: 'profile/:uuid',
              element: (
                <AuthGuard>
                  <UserProfile />
                </AuthGuard>
              ),
            },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            // { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: PATH_PAGE.logoutRedirect,
          element: <LogoutRedirect />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        // { path: 'terms-and-conditions', element: <TermsAndConditions /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
